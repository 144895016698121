@tailwind components;
@tailwind base;
@tailwind utilities;

@layer base {
  h1 {
    @apply md:text-5xl text-3xl font-custom; //TODO: relook
  }

  h2 {
    @apply md:text-4xl text-3xl font-custom; //TODO: relook
  }

  h3 {
    @apply md:text-3xl text-2xl font-custom;
  }

  h4 {
    @apply md:text-2xl text-xl font-custom;
  }

  h5 {
    @apply md:text-xl text-lg font-custom;
  }

  h6 {
    @apply md:text-lg text-base font-custom;
  }

  div {
    @apply font-custom;
  }

  span {
    @apply font-custom;
  }

  p {
    @apply text-base font-custom;
  }

  pre {
    @apply text-base font-custom;
  }

  button {
    @apply font-custom;
  }

  a {
    @apply font-custom;
  }

  label {
    @apply font-custom;
  }
}

@layer components {
  .btn {
    @apply focus:outline-none outline-none;
  }

  /* .btn {
    @apply px-7 py-2.5 rounded focus:outline-none outline-none;
  } */

  /* .btn-primary {
    @apply btn bg-cyan-900 text-white hover:bg-white hover:text-cyan-900 transition-colors duration-300 border border-cyan-900;
  }

  .btn-secondary {
    @apply btn bg-white border border-cyan-900 text-cyan-900 hover:bg-cyan-900 hover:text-white transition-colors duration-300;
  } */
}

@layer utilities {
  .scrollbar-hidden::-webkit-scrollbar {
    width: 0;
    height: 0;
    background-color: transparent;
  }

  .translate-x-5\.5 {
    --tw-translate-x: 1.4rem;
  }

  .translate-x-4\.5 {
    --tw-translate-x: 1.1rem;
  }
};
